export default {
    zh:{
        bgzlContent:{
            queryParam:{
            },
            columns:{
            },
            form:{
                bghthm:'报关合同号码',
                bghthm_placeholder:'请输入报关合同号码',
                date:'日期',
                date_placeholder:'请选择日期',
                xt:'噱头',
                xt_placeholder:'请输入噱头',
                mjmc:'公司/买家名称',
                mjmc_placeholder:'请输入公司/买家名称',
                lxdz:'联系地址',
                lxdz_placeholder:'请输入联系地址',
                lxdh:'联系电话',
                lxdh_placeholder:'请输入联系电话',
                qyg:'启运港',
                qyg_placeholder:'请输入启运港',
                jhd:'接货地',
                jhd_placeholder:'请输入接货地',
                mdg:'目的港',
                mdg_placeholder:'请输入目的港',
                fphm:'发票号码',
                fphm_placeholder:'请输入发票号码',
                bx:'保险',
                bx_placeholder:'请输入保险',
                bz:'包装',
                bz_placeholder:'请输入包装',
            },
            BgzlContentDetail:{
                F_HWMS:'货物描述',
                F_HWMS_PLACEHOLDER:'请输入货物描述',
                F_NUM:'数量',
                F_PRICE:'单价',
                F_AMOUNT:'金额',
                F_AMOUNT_PLACEHOLDER:'请输入金额',
                F_JS:'件数',
                F_BZ:'包装',
                F_BZ_PLACEHOLDER:'请输入包装',
                F_MZ:'毛重',
                F_JZ:'净重',
                F_TJ:'体积',
            },
        }
    },
    en:{
        bgzlContent:{
            queryParam:{
            },
            columns:{
            },
            form:{
                bghthm:'报关合同号码',
                bghthm_placeholder:'请输入报关合同号码',
                date:'日期',
                date_placeholder:'请选择日期',
                xt:'噱头',
                xt_placeholder:'请输入噱头',
                mjmc:'公司/买家名称',
                mjmc_placeholder:'请输入公司/买家名称',
                lxdz:'联系地址',
                lxdz_placeholder:'请输入联系地址',
                lxdh:'联系电话',
                lxdh_placeholder:'请输入联系电话',
                qyg:'启运港',
                qyg_placeholder:'请输入启运港',
                jhd:'接货地',
                jhd_placeholder:'请输入接货地',
                mdg:'目的港',
                mdg_placeholder:'请输入目的港',
                fphm:'发票号码',
                fphm_placeholder:'请输入发票号码',
                bx:'保险',
                bx_placeholder:'请输入保险',
                bz:'包装',
                bz_placeholder:'请输入包装',
            },
            BgzlContentDetail:{
                F_HWMS:'货物描述',
                F_HWMS_PLACEHOLDER:'请输入货物描述',
                F_NUM:'数量',
                F_PRICE:'单价',
                F_AMOUNT:'金额',
                F_AMOUNT_PLACEHOLDER:'请输入金额',
                F_JS:'件数',
                F_BZ:'包装',
                F_BZ_PLACEHOLDER:'请输入包装',
                F_MZ:'毛重',
                F_JZ:'净重',
                F_TJ:'体积',
            },
        }
    }
}